<template>
  <div class="d-flex flex-column flex-fill tlw-h-screen">
    <hsLoading v-if="isLoading" class="flex-fill" />
    <template v-else>
      <header class="tlw-text-center tlw-px-20 tlw-py-10">
        <h1 class="">{{ $t('landing-page.views.scheduling.heading') }} 🗓️</h1>
        <p class="pt-5">
          {{ $t('landing-page.views.scheduling.description') }}
        </p>
      </header>
      <main class="position-relative d-flex flex-column tlw-min-h-full">
        <iframe ref="frame" class="tlw-h-full" :src="frameUrl" frameborder="0" />
      </main>
      <section class="tlw-px-20 tlw-pt-1 tlw-text-center">
        <h2 class="tlw-text-white tlw-bg-red-500 tlw-inline-block mx-auto">
          {{ $t('landing-page.views.scheduling.call') }}
        </h2>
        <div class="tlw-grid tlw-gap-1 tlw-grid-cols-1 md:tlw-grid-cols-3 tlw-pt-10">
          <div class="tlw-text-left">
            <img
              class="tlw-mx-auto tlw-w-1/3"
              src="@/assets/images/scheduling/icone-alunos.png"
              :alt="$t('landing-page.views.scheduling.custom.title')"
            />
            <h3 class="tlw-text-red-500 tlw-pt-5">
              {{ $t('landing-page.views.scheduling.custom.title') }}
            </h3>
            <p class="pt-5">
              {{ $t('landing-page.views.scheduling.custom.description') }}
            </p>
          </div>
          <div class="tlw-text-left">
            <img
              class="tlw-mx-auto tlw-w-1/3"
              src="@/assets/images/scheduling/icone-cursos.png"
              :alt="$t('landing-page.views.scheduling.solutions.title')"
            />
            <h3 class="tlw-text-red-500 tlw-pt-5">
              {{ $t('landing-page.views.scheduling.solutions.title') }}
            </h3>
            <p class="pt-5">
              {{ $t('landing-page.views.scheduling.solutions.description') }}
            </p>
          </div>
          <div class="tlw-text-left">
            <img
              class="tlw-mx-auto tlw-w-1/3"
              src="@/assets/images/scheduling/icone-foguete.png"
              :alt="$t('landing-page.views.scheduling.cases.title')"
            />
            <h3 class="tlw-text-red-500 tlw-pt-5">
              {{ $t('landing-page.views.scheduling.cases.title') }}
            </h3>
            <p class="pt-5">
              {{ $t('landing-page.views.scheduling.cases.description') }}
            </p>
          </div>
        </div>
      </section>
      <section class="tlw-px-20 tlw-text-center tlw-pt-10">
        <h2 class="tlw-text-white tlw-bg-gray-900 tlw-inline-block mx-auto">
          {{ $t('landing-page.views.scheduling.faq') }}
        </h2>
        <div class="tlw-text-left">
          <h3 class="tlw-text-red-500 tlw-pt-10">
            "{{ $t('landing-page.views.scheduling.questions.first-question') }}"
          </h3>
          <p class="tlw-pt-5">
            {{ $t('landing-page.views.scheduling.questions.first-answer-1') }}
          </p>
          <h3 class="tlw-text-red-500 tlw-pt-10">
            "{{ $t('landing-page.views.scheduling.questions.second-question') }}"
          </h3>
          <p class="tlw-pt-5">
            {{ $t('landing-page.views.scheduling.questions.second-answer-1') }}
          </p>
          <p class="tlw-pt-5">
            {{ $t('landing-page.views.scheduling.questions.second-answer-2') }}
          </p>
          <h3 class="tlw-text-red-500 tlw-pt-10">
            "{{ $t('landing-page.views.scheduling.questions.third-question') }}"
          </h3>
          <p class="tlw-pt-5">
            {{ $t('landing-page.views.scheduling.questions.third-answer-1') }}
          </p>
          <p class="tlw-pt-5">
            {{ $t('landing-page.views.scheduling.questions.third-answer-2') }}
          </p>
          <h3 class="tlw-text-red-500 tlw-pt-10">
            "{{ $t('landing-page.views.scheduling.questions.forth-question') }}"
          </h3>
          <p class="tlw-pt-5">
            {{ $t('landing-page.views.scheduling.questions.forth-answer-1') }}
          </p>
          <p class="tlw-py-5">
            {{ $t('landing-page.views.scheduling.questions.forth-answer-2') }}
          </p>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import { hsLoading } from '@/components';
import { mapState } from 'vuex';

const hsUrl = 'https://meetings.hubspot.com/elivelton-pontes/demonstracao-solucao-herospark';

export default {
  name: 'SchedulingLandingPage',
  components: { hsLoading },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState('school', {
      school: state => state.selectedSchool,
    }),
    frameUrl() {
      const emailParam = `email=${this.school.email}`;
      const phoneParam = `phone=${this.school.phone || ''}`;
      const firstName = this.school.name.split(' ')[0] || '';
      const firstNameParam = `firstName=${firstName}`;
      const lastName = this.school.name.replace(firstName, '') || '';
      const lastNameParam = `lastName=${lastName.replace(' ', '')}`;
      return `${hsUrl}?${emailParam}&${phoneParam}&${firstNameParam}&${lastNameParam}`;
    },
  },
  mounted() {
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.btn-ensure-access {
  width: 277px;
  height: 80px;
  font-size: 20px;
}

#info-products-image {
  width: 560px;
  height: 548px;
  right: 0px;
  top: -100px;
}

@media only screen and (min-width: $screen-md) {
  .title-text {
    font-size: 30px;
  }

  .details-members-container {
    width: 40% !important;
  }
}
</style>
